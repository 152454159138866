import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

export const ResolvedInlineCommentsQuery = gql`
	query ResolvedInlineCommentsQuery($pageId: ID!, $contentStatus: [GraphQLContentStatus!]) {
		comments(pageId: $pageId, contentStatus: $contentStatus, type: RESOLVED) {
			nodes {
				...inlineCommentFragment
				location {
					... on InlineComment {
						type
						inlineMarkerRef
						inlineText
						inlineResolveProperties {
							resolvedByDangling
							resolvedTime
							resolvedFriendlyDate
							resolvedUser
							resolved
						}
					}
				}
				createdAt {
					value
				}
				replies {
					...inlineCommentFragment
					createdAt {
						value
					}
				}
			}
			totalCount
		}
	}
	${inlineCommentFragment}
`;
