import gql from 'graphql-tag';

import { inlineCommentFragment } from './InlineCommentFragment.fragment';

export const ActiveInlineCommentsQuery = gql`
	query ActiveInlineCommentsQuery(
		$inlineMarkerRefList: [String!]!
		$pageId: ID!
		$contentStatus: [GraphQLContentStatus]
	) {
		comments(
			inlineMarkerRefList: $inlineMarkerRefList
			pageId: $pageId
			contentStatus: $contentStatus
		) {
			nodes {
				...inlineCommentFragment
				location {
					... on InlineComment {
						type
						inlineMarkerRef
						inlineText
						inlineResolveProperties {
							resolvedByDangling
							resolvedTime
							resolvedFriendlyDate
							resolvedUser
							resolved
						}
					}
				}
				createdAt {
					value
				}
				replies {
					...inlineCommentFragment
					createdAt {
						value
					}
					reactionsSummary(pageId: $pageId, contentType: "page", childType: "comment") {
						ari
						containerAri
						reactionsCount
						reactionsSummaryForEmoji {
							id
							emojiId
							count
							reacted
						}
					}
				}
				reactionsSummary(pageId: $pageId, contentType: "page", childType: "comment") {
					ari
					containerAri
					reactionsCount
					reactionsSummaryForEmoji {
						id
						emojiId
						count
						reacted
					}
				}
			}
		}
	}
	${inlineCommentFragment}
`;
