import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl-next';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';

import { fg } from '@confluence/feature-gating';
import { useObjectSidebar, PanelName } from '@confluence/object-sidebar-api';
import {
	getRendererAnnotationEventEmitter,
	getEditorAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';

import { useCommentsPanel, type ViewValues } from './hooks/useCommentsPanel';
import { CommentsPanelHeaderBadge } from './CommentsPanelHeaderBadge';
import { CommentsPanelFilterMenu } from './CommentsPanelFilterMenu';
import { CommentsPanelWrapper } from './CommentsPanelWrapper';
import { useUnreadComments } from './hooks/useUnreadComments';

export const i18n = defineMessages({
	commentsPanelHeaderLabel: {
		id: 'comments-panel.panel.header.label',
		defaultMessage: 'Comments',
		description: 'Label for the comments panel header',
	},
});

export const useShowCommentsPanel = () => {
	const { handleMarkCommentsAsRead } = useUnreadComments();
	const [shouldClearUnreads, setShouldClearUnreads] = useState<boolean>(false);
	const [{ isObjectSidebarShown, panel }, { showObjectSidebar, hideObjectSidebar }] =
		useObjectSidebar();

	const [, { setCurrentView, setCurrentlySelectedCommentMarkerRef }] = useCommentsPanel();

	const pageMode = useGetPageMode();
	const eventEmitter =
		pageMode === PageMode.VIEW
			? getRendererAnnotationEventEmitter()
			: getEditorAnnotationEventEmitter();

	// Required to invoke handleMarkCommentsAsRead and if any commentIdsByAnnotationToMarkAsRead
	// then we will call the MarkCommentsReadMutation Mutation
	useEffect(() => {
		if (shouldClearUnreads) {
			handleMarkCommentsAsRead();
		}
		return () => {
			setShouldClearUnreads(false);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldClearUnreads, setShouldClearUnreads]);

	const onPanelClose = () => {
		setCurrentView(undefined);
		setShouldClearUnreads(true);

		// Unselect active annotations on the page
		if (pageMode === PageMode.VIEW) {
			eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
			eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
		} else {
			eventEmitter.emit('setselectedannotation', '');
		}

		// clear selected comment thread in the comments panel
		setCurrentlySelectedCommentMarkerRef('');
	};

	const canClosePanel = () => {
		// TODO: Check if the content has changed before we allow the closing of the panel
		return true;
	};

	const showCommentsPanel = ({ openWithView }: { openWithView?: ViewValues } = {}) => {
		showObjectSidebar(
			{
				id: PanelName.CommentsPanel,
				headerComponentElements: {
					headerLabel: i18n.commentsPanelHeaderLabel,
					HeaderAfterIconElement: CommentsPanelHeaderBadge,
					HeaderRightAlignedElement: fg('confluence_frontend_comments_panel_v2')
						? CommentsPanelFilterMenu
						: undefined,
				},
				BodyComponent: () => CommentsPanelWrapper({ openWithView }),
				closeOptions: {
					canClosePanel,
					onPanelClose,
				},
			},
			'push',
		);
	};

	// NOTE: Exposing the hide function for now while we have the CommentButton
	return {
		isCommentsPanelShown: isObjectSidebarShown && panel?.id === PanelName.CommentsPanel,
		showCommentsPanel,
		hideCommentsPanel: hideObjectSidebar,
	};
};
