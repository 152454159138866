import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook, createActionsHook, createStateHook } from 'react-sweet-state';

export enum ViewValues {
	OPEN = 'OPEN',
	UNREAD = 'UNREAD',
	RESOLVED = 'RESOLVED',
}

export enum SortValues {
	PAGE_ORDER = 'PAGE_ORDER',
	MOST_RECENT = 'MOST_RECENT',
}

export type CommentsPanelState = {
	currentView: ViewValues | undefined;
	currentSort: SortValues;
	// NOTE: When there are more types, should use an array or some other data structure
	showInlineComments: boolean;
	showPageComments: boolean;
	currentlySelectedCommentMarkerRef: string | undefined;
	commentIdsByAnnotationToMarkAsRead: Record<string, Set<string>>; // maps annotation to set of comment IDs
	initialDataLoadedForViewMap: Record<ViewValues, boolean>;
	replyToCommentId: string | undefined;
};

const initialState: CommentsPanelState = {
	currentView: undefined,
	currentSort: SortValues.PAGE_ORDER,
	showInlineComments: true,
	showPageComments: true,
	currentlySelectedCommentMarkerRef: undefined,
	commentIdsByAnnotationToMarkAsRead: {},
	initialDataLoadedForViewMap: {
		[ViewValues.UNREAD]: false,
		[ViewValues.OPEN]: false,
		[ViewValues.RESOLVED]: false,
	},
	replyToCommentId: undefined,
};

// Actions for managing the comments state in the comments panel
const actions = {
	setCurrentView:
		(view: ViewValues | undefined) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentView: view });
		},
	setCurrentSort:
		(sort: SortValues) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentSort: sort });
		},
	setShowInlineComments:
		(showInline: boolean) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ showInlineComments: showInline });
		},
	setShowPageComments:
		(showPage: boolean) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ showPageComments: showPage });
		},
	setCurrentlySelectedCommentMarkerRef:
		(markerRef: string | undefined) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ currentlySelectedCommentMarkerRef: markerRef });
		},
	setCommentIdsByAnnotationToMarkAsRead:
		({
			parentCommentMarkerRef,
			commentIds,
			clearList = false,
		}: {
			parentCommentMarkerRef?: string;
			commentIds?: string[];
			clearList?: boolean;
		}) =>
		({ setState, getState }: StoreActionApi<CommentsPanelState>) => {
			const { commentIdsByAnnotationToMarkAsRead } = getState();
			if (clearList) {
				setState({ commentIdsByAnnotationToMarkAsRead: {} });
			} else {
				if (parentCommentMarkerRef && commentIds) {
					// Add the new comment IDs to the existing set for the specific parent
					const updatedCommentIds = { ...commentIdsByAnnotationToMarkAsRead };
					const existingIdsSet = updatedCommentIds[parentCommentMarkerRef] || new Set();
					commentIds.forEach((id) => existingIdsSet.add(id));
					// Update the map with the new set of IDs
					updatedCommentIds[parentCommentMarkerRef] = existingIdsSet;
					setState({ commentIdsByAnnotationToMarkAsRead: updatedCommentIds });
				}
			}
		},
	setInitialDataLoadedForView:
		({ viewToSetLoaded }: { viewToSetLoaded: ViewValues }) =>
		({ getState, setState }: StoreActionApi<CommentsPanelState>) => {
			const { initialDataLoadedForViewMap } = getState();

			initialDataLoadedForViewMap[viewToSetLoaded] = true;

			setState({ initialDataLoadedForViewMap });
		},
	setReplyToCommentId:
		(commentIdToReplyTo?: string) =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({ replyToCommentId: commentIdToReplyTo });
		},
	resetInitialDataLoadedMap:
		() =>
		({ setState }: StoreActionApi<CommentsPanelState>) => {
			setState({
				initialDataLoadedForViewMap: {
					[ViewValues.UNREAD]: false,
					[ViewValues.OPEN]: false,
					[ViewValues.RESOLVED]: false,
				},
			});
		},
};

const CommentsStore = createStore({
	initialState,
	actions,
	name: 'CommentsStore',
});

export const useCommentsPanel = createHook(CommentsStore);
export const useCommentsPanelActions = createActionsHook(CommentsStore);
export const useCommentsPanelState = createStateHook(CommentsStore);
