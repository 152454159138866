import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-apollo';
import { defineMessages } from 'react-intl-next';

import type {
	ResolvedInlineCommentsQueryType,
	ActiveInlineCommentsQueryType,
} from '@confluence/inline-comments-queries';
import { ResolvedInlineCommentsQuery } from '@confluence/inline-comments-queries';
import type { CommentData } from '@confluence/comments-data';
import { updateCommentsDataState, useCommentsData } from '@confluence/comments-data';
import { usePageContentId } from '@confluence/page-context';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import { useGetPageMode } from '@confluence/page-utils/entry-points/useGetPageMode';
import { useSessionData } from '@confluence/session-data';
import { useEditorAnnotations } from '@confluence/inline-comments-hooks';
import type { FlagsStateContainer } from '@confluence/flags';

import { useCommentsPanel, ViewValues } from '../hooks/useCommentsPanel';
import { CommentsPanelList } from '../components/CommentsPanelList';
import { Loading } from '../components/Loading';
import { ErrorPanel } from '../components/ErrorPanel';
import { EmptyComments } from '../components/EmptyComments';

type ResolvedViewProps = {
	flags?: FlagsStateContainer;
};

const i18n = defineMessages({
	noResolvedCommentsText: {
		id: 'comments-panel.resolved.view.empty.text',
		defaultMessage: 'There are no resolved comments.',
		description:
			'Text to display in Resolved View when there are no resolved comments for the page.',
	},
});

const getResolvedCommentThreads = ({
	inlineCommentsDataMap,
}: {
	inlineCommentsDataMap: Record<string, CommentData>;
}) => {
	const resolvedCommentThreads: CommentData[] = [];
	for (const key in inlineCommentsDataMap) {
		const comment = inlineCommentsDataMap[key];
		if (comment.isOpen === false) {
			resolvedCommentThreads.push(comment);
		}
	}
	return resolvedCommentThreads;
};

export const ResolvedView = ({ flags }: ResolvedViewProps) => {
	const [loading, setLoading] = useState(true);
	const [contentId] = usePageContentId();

	const [
		{ inlineCommentsDataMap },
		{ addNewCommentThreads, setInlineCommentsDataMap, clearRemovedComments },
	] = useCommentsData();
	const [{ readCommentsListState }] = useUnreadInlineComments();
	const [{ annotations }] = useEditorAnnotations();
	const pageMode = useGetPageMode();
	const { userId: currentUserId } = useSessionData();
	const [{ initialDataLoadedForViewMap }, { setInitialDataLoadedForView }] = useCommentsPanel();
	const isInitialCommentDataLoaded = initialDataLoadedForViewMap[ViewValues.RESOLVED];

	const annotationsInEditorDoc = useMemo(() => {
		return new Set(annotations);
	}, [annotations]);

	const {
		loading: queryLoading,
		error,
		refetch,
	} = useQuery<ResolvedInlineCommentsQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ResolvedInlineCommentsQuery,
		{
			variables: {
				pageId: contentId,
				contentStatus: ['DRAFT', 'CURRENT'],
			},
			onCompleted: (data) => {
				const hasAlreadyFetchedInitialData = Object.keys(inlineCommentsDataMap).length > 0;
				updateCommentsDataState({
					data: data as ActiveInlineCommentsQueryType,
					readCommentsListState,
					updateData: hasAlreadyFetchedInitialData
						? addNewCommentThreads
						: setInlineCommentsDataMap,
					annotationsInEditorDoc,
					pageMode,
					currentUserId: currentUserId ?? '',
					isOpen: false,
				});

				setLoading(false);
			},
			onError: () => {
				setLoading(false);
			},
		},
	);

	useEffect(() => {
		if (queryLoading) {
			setLoading(true);
		}
	}, [queryLoading, setLoading]);

	useEffect(() => {
		if (!loading) {
			setInitialDataLoadedForView({ viewToSetLoaded: ViewValues.RESOLVED });
		}
	}, [loading, setInitialDataLoadedForView]);

	useEffect(() => {
		// When this view unmounts, we want to clear out the "resolved"/"deleted" comments
		return () => {
			clearRemovedComments();
		};
	}, [clearRemovedComments]);

	const resolvedThreads = useMemo(() => {
		if (!isInitialCommentDataLoaded && loading) {
			return [];
		}

		return getResolvedCommentThreads({
			inlineCommentsDataMap,
		});
	}, [loading, isInitialCommentDataLoaded, inlineCommentsDataMap]);

	if (!isInitialCommentDataLoaded) {
		if (loading) {
			return <Loading />;
		}

		if (error) {
			return (
				<ErrorPanel
					error={error}
					onRetryClick={async () => {
						await refetch();
					}}
				/>
			);
		}
	}

	if (resolvedThreads.length === 0) {
		return <EmptyComments message={i18n.noResolvedCommentsText} />;
	}

	return (
		<CommentsPanelList
			commentThreads={resolvedThreads}
			supportedTopLevelActions={['reopen']}
			flags={flags}
		/>
	);
};
