import React from 'react';
import type { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Flex, xcss } from '@atlaskit/primitives';
import DropdownMenu, {
	DropdownItem,
	DropdownItemCheckbox,
	DropdownItemGroup,
	DropdownItemCheckboxGroup,
} from '@atlaskit/dropdown-menu';
import { IconButton } from '@atlaskit/button/new';
import FilterIcon from '@atlaskit/icon/core/filter';

import { CommentWarningDialog } from '@confluence/comment-dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import {
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';

import { viewOptions, type ViewFilter } from './CommentsPanel';
import type { ViewValues } from './hooks/useCommentsPanel';
import { SortValues, useCommentsPanel } from './hooks/useCommentsPanel';

const i18n = defineMessages({
	filterLabel: {
		id: 'comments-panel.filter.label',
		defaultMessage: 'Filter Options',
		description: 'Label for button apply filters to comment panel',
	},
	filterByTitle: {
		id: 'comments-panel.filter.by.title',
		defaultMessage: 'Filter By',
		description: 'A title for the filter section',
	},
	sortByTitle: {
		id: 'comments-panel.sort.by.title',
		defaultMessage: 'Sort By',
		description: 'A title for the sort section',
	},
	pageOrder: {
		id: 'comments-panel.page-order.label',
		defaultMessage: 'Page Order',
		description: 'Label for the page order sort option',
	},
	showInlineComments: {
		id: 'comments-panel.show-inline-comments.label',
		defaultMessage: 'Show inline comments',
		description: 'Label for the show inline comments dropdown option',
	},
});

const dropDownMenuStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	paddingBottom: 'space.050',
	paddingLeft: 'space.300',
	paddingTop: 'space.050',
	gap: 'space.100',
});

export const CommentsPanelFilterMenu: FC = () => {
	const { formatMessage } = useIntl();
	const { showModal } = useDialogs();
	const { hasContentChanged } = useCommentContentContext();
	const { resetContentChanged } = useCommentContentDispatchContext();
	const [{ currentView, currentSort, showInlineComments }, { setCurrentView, setCurrentSort }] =
		useCommentsPanel();

	const updateView = (view: ViewValues) => {
		const handleClick = () => {
			// Do nothing if the same view is clicked
			if (currentView !== view) {
				setCurrentView && setCurrentView(view);
			}
		};

		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					handleClick();
				},
			});
		} else {
			handleClick();
		}
	};

	const updateSort = (sort: SortValues) => {
		// Do nothing if the same sort is clicked
		if (currentSort !== sort) {
			setCurrentSort && setCurrentSort(sort);
		}
	};

	return (
		<Flex role="tablist" xcss={dropDownMenuStyles}>
			<DropdownMenu
				trigger={({ triggerRef, ...props }) => (
					<IconButton
						testId="comments-panel-filter-button"
						ref={triggerRef}
						appearance="subtle"
						isTooltipDisabled={false}
						icon={FilterIcon}
						label={formatMessage(i18n.filterLabel)}
						{...props}
					/>
				)}
				testId="filter-selector-dropdown"
				placement="bottom-end"
				shouldRenderToParent
			>
				<DropdownItemGroup id="filter" title={formatMessage(i18n.filterByTitle)}>
					{viewOptions.map((view: ViewFilter) => (
						<DropdownItem
							key={view.name}
							onClick={() => updateView(view.name)}
							data-testId={`menu-item-${view.name}-filter`}
							isSelected={view.name === currentView}
						>
							{formatMessage(view.label)}
						</DropdownItem>
					))}
				</DropdownItemGroup>
				<DropdownItemGroup id="sort" title={formatMessage(i18n.sortByTitle)} hasSeparator>
					{/* TODO: Add "Most recent" option */}
					<DropdownItem
						onClick={() => updateSort(SortValues.PAGE_ORDER)}
						data-testId="menu-item-PAGE_ORDER-sort"
						isSelected={currentSort === SortValues.PAGE_ORDER}
					>
						{formatMessage(i18n.pageOrder)}
					</DropdownItem>
				</DropdownItemGroup>
				<DropdownItemGroup id="actions" hasSeparator>
					<>{/* TODO: Add "All" actions */}</>
				</DropdownItemGroup>
				<DropdownItemCheckboxGroup id="type">
					<DropdownItemCheckbox id="toggle-inline-comments" isSelected={showInlineComments}>
						{formatMessage(i18n.showInlineComments)}
					</DropdownItemCheckbox>
					{/* TODO: Add Page comments filter*/}
				</DropdownItemCheckboxGroup>
			</DropdownMenu>
		</Flex>
	);
};
