import React, { useState, useMemo, useCallback, useRef } from 'react';

import { AnnotationTypes } from '@atlaskit/adf-schema';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import { xcss, Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { N40 } from '@atlaskit/theme/colors';

import type { CommentReply, CommentLocation } from '@confluence/inline-comments-queries';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';
import type { CommentData } from '@confluence/comments-data';
import { fg } from '@confluence/feature-gating';
import { useInlineCommentQueryParams } from '@confluence/comment';
import { usePageInfo } from '@confluence/page-info';
import type { FlagsStateContainer } from '@confluence/flags';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';

import {
	type CommentThreadMouseEnterParams,
	handleCommentThreadMouseEnter,
	handleCommentThreadMouseLeave,
	handleCommentThreadSelection,
} from '../helper/commentThreadHelper';
import { useCommentsPanel } from '../hooks/useCommentsPanel';

import { CommentReplies } from './CommentReplies';
import { Comment } from './Comment';

export type CommentThreadProps = {
	parentComment: CommentData;
	supportedTopLevelActions: CommentAction[];
	eventEmitter: AnnotationUpdateEmitter;
	pageMode: PageMode;
	flags?: FlagsStateContainer;
};

const dividerStyle = xcss({
	borderBottom: `1px solid ${token('color.border', N40)}`,
	position: 'relative',
	top: `space.200`,
});

const commentThreadBoxStyles = xcss({
	listStyle: 'none',
	position: 'relative',
	paddingRight: 'space.300',
	paddingLeft: 'space.300',
	paddingTop: 'space.200',
	paddingBottom: 'space.200',
	margin: '0',
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.150',
});

const commentBodyBoxStyles = xcss({
	listStyle: 'none',
	position: 'relative',
});

const isCurrentSelectedCommentStyles = xcss({
	background: token('color.background.selected'),
});

const isThreadHoveredStyles = xcss({
	background: token('elevation.surface.hovered'),
});

// This component contains parent comment and its replies
export const CommentThread = ({
	parentComment,
	supportedTopLevelActions,
	eventEmitter,
	pageMode,
	flags,
}: CommentThreadProps) => {
	const [
		{ currentlySelectedCommentMarkerRef, commentIdsByAnnotationToMarkAsRead },
		{ setCurrentlySelectedCommentMarkerRef, setCommentIdsByAnnotationToMarkAsRead },
	] = useCommentsPanel();
	const { pageInfo } = usePageInfo();

	const parentCommentMarkerRef = (parentComment?.location as CommentLocation).inlineMarkerRef ?? '';

	const selectedAnnotation = {
		id: parentCommentMarkerRef || '',
		type: AnnotationTypes.INLINE_COMMENT,
	};

	const allReplies = parentComment.replies.filter((r) => (r as CommentReply) !== null);
	const unreadReplies = allReplies.filter((r) => r.isUnread);

	// State
	const [isThreadHovered, setIsThreadHovered] = useState<boolean>(false);
	const [hoveredCommentId, setHoveredCommentId] = useState<string | undefined>(undefined);

	// Hook dependent constants
	const isCurrentSelectedComment = currentlySelectedCommentMarkerRef === parentCommentMarkerRef;

	const { editCommentId: editCommentQueryId } = useInlineCommentQueryParams();

	const handleCommentThreadHoverProps: CommentThreadMouseEnterParams = useMemo(
		() => ({
			parentCommentMarkerRef,
			setIsThreadHovered,
			setHoveredCommentId,
			pageMode,
			eventEmitter,
		}),
		[parentCommentMarkerRef, setIsThreadHovered, setHoveredCommentId, pageMode, eventEmitter],
	);

	const handleMouseEnter = useCallback(
		(id?: string) => {
			handleCommentThreadMouseEnter({
				...handleCommentThreadHoverProps,
				id,
			});
		},
		[handleCommentThreadHoverProps],
	);

	const handleMouseLeave = useCallback(() => {
		handleCommentThreadMouseLeave(handleCommentThreadHoverProps);
	}, [handleCommentThreadHoverProps]);

	const userActionBackgroundStyles = isCurrentSelectedComment
		? isCurrentSelectedCommentStyles // Apply styles for the currently selected comment
		: isThreadHovered
			? isThreadHoveredStyles // Apply styles if the thread is hovered
			: undefined; // No special styles applied

	const commentThreadSelectedRef = useRef(false);
	const unreadParentCommentAlreadyMarkedAsReadRef = useRef(false);

	// handle adding the parent comment to list of IDs to mark as read
	if (
		commentThreadSelectedRef.current &&
		!unreadParentCommentAlreadyMarkedAsReadRef.current &&
		parentComment.isUnread
	) {
		setCommentIdsByAnnotationToMarkAsRead({
			parentCommentMarkerRef,
			commentIds: [parentComment.id],
		});
		unreadParentCommentAlreadyMarkedAsReadRef.current = true;
	}

	return (
		// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage, jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions -- Ignored via go/DSP-18766
		<Box
			testId={`comment-thread-${parentCommentMarkerRef}-container`}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/design-system/consistent-css-prop-usage -- Ignored via go/DSP-18766
			xcss={[commentThreadBoxStyles, userActionBackgroundStyles]}
			as="li"
			onClick={() =>
				handleCommentThreadSelection({
					parentComment,
					pageMode,
					setCurrentlySelectedCommentMarkerRef,
					eventEmitter,
					commentThreadSelectedRef,
				})
			}
		>
			<Box
				testId={`comment-thread-${parentComment.id}-parent`}
				xcss={commentBodyBoxStyles}
				onMouseEnter={() => handleMouseEnter(parentComment.id)}
				onMouseLeave={handleMouseLeave}
			>
				<Comment
					comment={parentComment}
					supportedTopLevelActions={supportedTopLevelActions}
					pageInfo={pageInfo}
					pageMode={pageMode}
					isUnread={
						!commentIdsByAnnotationToMarkAsRead[parentCommentMarkerRef]?.has(parentComment.id) &&
						parentComment.isUnread
					}
					isHovered={hoveredCommentId === parentComment.id}
					parentCommentMarkerRef={parentCommentMarkerRef}
					flags={flags}
				/>
			</Box>
			<CommentReplies
				parentComment={parentComment}
				allReplies={allReplies}
				unreadReplies={unreadReplies}
				pageInfo={pageInfo}
				isCurrentSelectedComment={isCurrentSelectedComment}
				hoveredCommentId={hoveredCommentId ?? ''}
				selectedAnnotation={selectedAnnotation}
				editCommentQueryId={editCommentQueryId ?? ''}
				pageMode={pageMode}
				parentCommentId={parentComment.id}
				handleMouseEnter={handleMouseEnter}
				handleMouseLeave={handleMouseLeave}
				commentThreadSelectedRef={commentThreadSelectedRef}
			/>
			{fg('confluence_frontend_comments_panel_v2') && <Box xcss={[dividerStyle]} />}
		</Box>
	);
};
