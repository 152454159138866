import { COMMENT_BATCH_SIZE } from '@confluence/comments-data';
import type { AnnotationStatus, CommentData } from '@confluence/comments-data';

export const scrollCommentsPanel = ({
	containerId,
	commentMarkerRef,
}: {
	containerId: string;
	commentMarkerRef: string;
}) => {
	const container = document.querySelector(`[data-testid='${containerId}']`);
	if (container) {
		const element = document.querySelector(`[data-testid='${commentMarkerRef}']`);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}
};

export const getAnnotationsToLoad = (orderedActiveAnnotationIdList: AnnotationStatus[]) => {
	return orderedActiveAnnotationIdList
		.slice(0, Math.min(orderedActiveAnnotationIdList.length, COMMENT_BATCH_SIZE))
		.filter((item) => !item.isLoaded) // only get the ones that are not loaded yet
		.map((item) => item.annotationId);
};

// organize a list of open comment threads that we have data for
export const getOpenCommentThreads = ({
	inlineCommentsDataMap,
	orderedActiveAnnotationIdList,
	removedAnnotations,
}: {
	inlineCommentsDataMap: Record<string, CommentData>;
	orderedActiveAnnotationIdList: AnnotationStatus[];
	removedAnnotations: Record<number, string>;
}) => {
	const openCommentThreads: CommentData[] = [];

	const removedAnnotationIds = new Set(Object.values(removedAnnotations));
	const filteredActiveAnnotationIds = orderedActiveAnnotationIdList.filter(
		(item) => !removedAnnotationIds.has(item.annotationId),
	);
	const totalAnnotationsCount =
		filteredActiveAnnotationIds.length + Object.keys(removedAnnotations).length;

	let activeIndex = 0; // Track position in filteredActiveAnnotationIds

	for (let i = 0; i < totalAnnotationsCount; i++) {
		let annotationId = '';

		if (removedAnnotations.hasOwnProperty(i)) {
			annotationId = removedAnnotations[i];
			const commentThread = inlineCommentsDataMap[annotationId];
			if (commentThread) {
				openCommentThreads.push(commentThread);
			}
		} else if (activeIndex < filteredActiveAnnotationIds.length) {
			annotationId = filteredActiveAnnotationIds[activeIndex].annotationId;
			const commentThread = inlineCommentsDataMap[annotationId];
			if (commentThread) {
				openCommentThreads.push(commentThread);
			}
			activeIndex++;
		}
	}
	return openCommentThreads;
};

// organize a list of unread comment threads that we have data for
export const getUnreadCommentThreads = ({
	numUnreadComments,
	inlineCommentsDataMap,
	orderedActiveAnnotationIdList,
	removedAnnotations,
}: {
	numUnreadComments: number;
	inlineCommentsDataMap: Record<string, CommentData>;
	orderedActiveAnnotationIdList: AnnotationStatus[];
	removedAnnotations: Record<number, string>;
}) => {
	const unreadCommentThreads: CommentData[] = [];

	if (
		Object.keys(inlineCommentsDataMap).length > 0 &&
		(numUnreadComments > 0 || Object.keys(removedAnnotations).length > 0)
	) {
		const removedAnnotationIds = new Set(Object.values(removedAnnotations));
		const filteredActiveAnnotationIds = orderedActiveAnnotationIdList.filter(
			(item) => !removedAnnotationIds.has(item.annotationId),
		);
		const totalAnnotationsCount =
			filteredActiveAnnotationIds.length + Object.keys(removedAnnotations).length;

		let activeIndex = 0; // Track position in filteredActiveAnnotationIds

		for (let i = 0; i < totalAnnotationsCount; i++) {
			let annotationId = '';

			if (removedAnnotations.hasOwnProperty(i)) {
				annotationId = removedAnnotations[i];
				const commentThread = inlineCommentsDataMap[annotationId];
				if (commentThread?.isUnread || commentThread?.replies?.some((reply) => reply.isUnread)) {
					unreadCommentThreads.push(commentThread);
				}
			} else if (activeIndex < filteredActiveAnnotationIds.length) {
				annotationId = filteredActiveAnnotationIds[activeIndex].annotationId;
				const commentThread = inlineCommentsDataMap[annotationId];
				if (commentThread?.isUnread || commentThread?.replies?.some((reply) => reply.isUnread)) {
					unreadCommentThreads.push(commentThread);
				}
				activeIndex++;
			}
		}
	}
	return unreadCommentThreads;
};
