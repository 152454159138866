import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import Heading from '@atlaskit/heading';
import { Stack, Text, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';

import { EmptyUnreadSVG } from '../assets/EmptyUnreadSVG';
import { useCommentsPanel, ViewValues } from '../hooks/useCommentsPanel';

const emptyUnreadStyles = xcss({
	alignItems: 'center',
	gap: 'space.200',
	paddingTop: 'space.500',
});

const buttonStyle = css({
	marginTop: `${token('space.negative.050')}`,
});

const i18n = defineMessages({
	emptyUnreadCommentsHeaderText: {
		id: 'comments-panel.empty.unread.comments.header.text',
		defaultMessage: 'No unread comments',
		description:
			'Header text to display in Unread View empty state when there are no unread comments on the page.',
	},
	emptyUnreadCommentsBodyText: {
		id: 'comments-panel.empty.unread.comments.body.text',
		defaultMessage: `Comments you haven’t read yet will appear here.`,
		description:
			'Body text to display in Unread View empty state when there are no unread comments on the page.',
	},
	emptyUnreadCommentsViewOpenComments: {
		id: 'comments-panel.empty.unread.comments.view.open.comments',
		defaultMessage: 'View open comments',
		description: 'View open comments call to action for unread comments empty state',
	},
});

export const EmptyUnreadComponent = () => {
	const [, { setCurrentView }] = useCommentsPanel();

	const { formatMessage } = useIntl();

	return (
		<Stack xcss={emptyUnreadStyles} testId="comments-panel-empty-unread-component">
			<EmptyUnreadSVG />
			<Heading size="small">{formatMessage(i18n.emptyUnreadCommentsHeaderText)}</Heading>
			<Text align="center">{formatMessage(i18n.emptyUnreadCommentsBodyText)}</Text>

			<div css={buttonStyle}>
				<Button
					onClick={() => {
						setCurrentView(ViewValues.OPEN);
					}}
					testId="comments-panel-view-open-comments-button"
				>
					{formatMessage(i18n.emptyUnreadCommentsViewOpenComments)}
				</Button>
			</div>
		</Stack>
	);
};
