import React, { useEffect, useState, useRef, type FC } from 'react';

import { useUnreadInlineComments } from '@confluence/unread-comments';
import { fg } from '@confluence/feature-gating';

import { CommentsPanel } from './CommentsPanel';
import { Loading } from './components/Loading';
import { ViewValues, SortValues, useCommentsPanelActions } from './hooks/useCommentsPanel';

type CommentsPanelWrapperProps = {
	openWithView?: ViewValues;
};

export const CommentsPanelWrapper: FC<CommentsPanelWrapperProps> = ({ openWithView }) => {
	const [showLoader, setShowLoader] = useState(!openWithView);
	const [{ unreadCommentsListState: unreadCommentList, isUnreadQueryLoading }] =
		useUnreadInlineComments();

	const { setCurrentView, setCurrentSort, setShowInlineComments, setShowPageComments } =
		useCommentsPanelActions();

	// As long as we don't have a "default" view to open the panel to show then we should check
	// if the unread call is still in flight to figure out which view to show if there are unread
	// NOTE: In v2 we will always go to 'Open' tab unless the intent is to show the 'Resolved' tab
	useEffect(() => {
		if (!openWithView) {
			setShowLoader(!fg('confluence_frontend_comments_panel_v2') ? isUnreadQueryLoading : false);
		}
	}, [isUnreadQueryLoading, openWithView]);

	const viewToShow = useRef(ViewValues.OPEN);

	useEffect(() => {
		// We need to allow the `Resolved` tab to be immediately opened if the user comes from the overflow menu
		if (openWithView) {
			viewToShow.current = openWithView;
		}

		if (fg('confluence_frontend_comments_panel_v2')) {
			setCurrentSort(SortValues.PAGE_ORDER);
			setShowInlineComments(true);
			setShowPageComments(true);
		} else {
			// Only run the unread logic when there is no view provided
			if (!openWithView) {
				if (unreadCommentList.length === 0) {
					// If no unreads then move to Open View
					viewToShow.current = ViewValues.OPEN;
				} else if (unreadCommentList.length > 0) {
					// If there is Unread and current view is undefined or Unread then move it to Unread or retain Unread view
					// otherwise any incoming Unread through pubsub will auto move the user to the Unread Pill while
					// they are on the Open/Resolved pill and going through it.
					// Unread should only get defaulted if there are any unreads on opening the comments panel
					viewToShow.current = ViewValues.UNREAD;
				}
			}
		}

		setCurrentView(viewToShow.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to set the view once on comments panel mount
	}, []);

	return showLoader ? <Loading /> : <CommentsPanel currentView={viewToShow.current} />;
};
