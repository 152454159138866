import React, { useEffect, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import { css } from '@compiled/react';

import { Box, xcss, Text, Inline } from '@atlaskit/primitives';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import { token } from '@atlaskit/tokens';
import AvatarGroup from '@atlaskit/avatar-group';

import type { ReplyData } from '@confluence/comments-data';
import { fg } from '@confluence/feature-gating';

import { ViewReplyOptions } from '../helper/commentThreadHelper';
import { useCommentsPanel } from '../hooks/useCommentsPanel';

const replyTogglerStyles = css({
	cursor: 'pointer',
	userSelect: 'none',
	display: 'flex',
	alignItems: 'center',
});

const unreadIndicatorStyle = xcss({
	display: 'flex',
	alignItems: 'center',
});

// This snippet is being used in CommentBody.tsx
const dotStyles = xcss({
	height: token('space.100', '8px'),
	width: token('space.100', '8px'),
	backgroundColor: 'color.background.brand.bold',
	borderRadius: '50%',
	margin: 'space.050',
});

const withSpacingStyles = css({
	padding: token('space.100'),
	marginLeft: token('space.300'),
});

export const defaultDisplayCount = 3;

const i18n = defineMessages({
	commentsPanelHideRepliesText: {
		id: 'comments-panel.comment-thread.hide.replies',
		defaultMessage: 'Hide replies',
		description: 'Text for hiding replies',
	},
	commentsPanelShowMoreRepliesText: {
		id: 'comments-panel.comment-thread.show.more.replies',
		defaultMessage: `Show {numOfMoreRepliesToDisplay, plural, one {# more reply} other {# more replies}}`,
		description: 'Text for showing more replies',
	},
});

const replyBranchStyles = css({
	position: 'relative',
	// for the curvy border
	'&::before': {
		content: "''",
		position: 'absolute',
		left: '-24px',
		top: '0',
		height: '18px',
		width: '12px',
		borderLeft: `2px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		borderBottom: `2px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		borderBottomLeftRadius: '7px',
	},
});

const textWithSpacingStyles = css({ paddingLeft: token('space.075') });

type ProfilePictureInfo = {
	displayName: string;
	profilePicturePath: string | undefined;
};

export const RepliesToggler = ({
	hiddenRepliesRef,
	replies,
	setRepliesToDisplay,
	unreadReplies,
	setReplyView,
	currentReplyView,
	parentCommentMarkerRef,
}: {
	hiddenRepliesRef?: { current: ReplyData[] | [] };
	replies: ReplyData[];
	setRepliesToDisplay: React.Dispatch<React.SetStateAction<ReplyData[]>>;
	unreadReplies: ReplyData[];
	setReplyView: (option: ViewReplyOptions) => void;
	currentReplyView: ViewReplyOptions;
	parentCommentMarkerRef: string;
}) => {
	const { formatMessage } = useIntl();

	const [{ commentIdsByAnnotationToMarkAsRead }, { setCommentIdsByAnnotationToMarkAsRead }] =
		useCommentsPanel();

	const numReplies = replies.length;
	const numOfMoreRepliesToDisplay = useRef(numReplies);
	const maxProfilePicturesToShow = 3;

	// filter out duplicate authors when we're showing the profile pictures
	const getProfilePicturesToShow = (repliesToFilter: ReplyData[]): ProfilePictureInfo[] => {
		if (!fg('confluence_frontend_comments_panel_v2')) {
			return [];
		}

		const uniqueAccountsMap = new Map<string, ProfilePictureInfo>();
		// Start from the end of the list and move backwards
		for (let i = repliesToFilter.length - 1; i >= 0; i--) {
			const reply = repliesToFilter[i];
			const { displayName, profilePicture } = reply.author;
			// Determine if the author has an accountId, anonymous users don't have accountId
			let uniqueKey: string;
			if ('accountId' in reply.author && reply.author.accountId) {
				uniqueKey = reply.author.accountId;
			} else {
				// Fallback for anonymous users or if accountId is not present
				uniqueKey = `anonymous-${i}`;
			}
			if (!uniqueAccountsMap.has(uniqueKey)) {
				uniqueAccountsMap.set(uniqueKey, {
					displayName: displayName ?? '',
					profilePicturePath: profilePicture?.path,
				});
			}
		}
		return Array.from(uniqueAccountsMap.values());
	};

	const profilePicturesHiddenReplies = useRef<ProfilePictureInfo[]>(
		getProfilePicturesToShow(replies),
	);

	const handleReplyViewClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		// if the current view is unread or it's hidden, then show all
		if (
			(replies.length > defaultDisplayCount && currentReplyView === ViewReplyOptions.DEFAULT) ||
			currentReplyView === ViewReplyOptions.HIDDEN
		) {
			setReplyView(ViewReplyOptions.ALL);
			setRepliesToDisplay(replies);
			// hide profile pictures since we're showing all replies already
			profilePicturesHiddenReplies.current = [];
		}

		// if it's already showing all, then hide
		if (currentReplyView === ViewReplyOptions.ALL) {
			setReplyView(ViewReplyOptions.HIDDEN);
			const displayReplyIds = unreadReplies.map((reply) => reply.id);
			setCommentIdsByAnnotationToMarkAsRead({
				parentCommentMarkerRef,
				commentIds: displayReplyIds,
			});
			setRepliesToDisplay([]);

			// show profile pictures since w're hiding all the replies
			profilePicturesHiddenReplies.current = getProfilePicturesToShow(replies);
			if (hiddenRepliesRef?.current) {
				hiddenRepliesRef.current = [];
			}
		}
	};

	useEffect(() => {
		// Determine if there are unread replies to show
		const hasUnreadReplies =
			unreadReplies.length > 0 &&
			parentCommentMarkerRef &&
			(commentIdsByAnnotationToMarkAsRead?.[parentCommentMarkerRef] === undefined ||
				commentIdsByAnnotationToMarkAsRead?.[parentCommentMarkerRef]?.size < unreadReplies.length);

		if (currentReplyView === ViewReplyOptions.ALL) {
			setRepliesToDisplay(replies);
			profilePicturesHiddenReplies.current = [];
		} else if (hasUnreadReplies) {
			const unreadRepliesToShow = unreadReplies.slice(
				-Math.min(unreadReplies.length, defaultDisplayCount),
			);

			setRepliesToDisplay(unreadRepliesToShow);

			// determine the hidden replies, we only want to show profile pictures for hidden replies
			const unreadReplyIdsSet = new Set(unreadRepliesToShow.map((reply) => reply.id));
			const hiddenReplies = replies.filter((reply) => !unreadReplyIdsSet.has(reply.id));
			profilePicturesHiddenReplies.current = getProfilePicturesToShow(hiddenReplies);

			// Determine the number of more replies to display
			if (numReplies > defaultDisplayCount) {
				numOfMoreRepliesToDisplay.current = replies.length - unreadRepliesToShow.length;
			}
			// Set the reply view based on the number of unread and total replies
			if (unreadReplies.length > defaultDisplayCount || replies.length > unreadReplies.length) {
				setReplyView(ViewReplyOptions.DEFAULT);
			} else {
				setReplyView(ViewReplyOptions.ALL);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [unreadReplies, currentReplyView, numReplies, replies]);

	const hideReplies = currentReplyView === ViewReplyOptions.ALL;

	// condition to check undefined is required if there are not unread marked to be read yet
	const canRenderBlueDotIndicator =
		unreadReplies.length > defaultDisplayCount &&
		(!commentIdsByAnnotationToMarkAsRead[parentCommentMarkerRef] ||
			commentIdsByAnnotationToMarkAsRead[parentCommentMarkerRef]?.size < unreadReplies.length);

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			data-testId="comments-panel-replies-toggler-container"
			css={[
				replyTogglerStyles,
				fg('confluence_frontend_comments_panel_v2') && withSpacingStyles,
				fg('confluence_frontend_comments_panel_v2') && replyBranchStyles,
			]}
			onClick={handleReplyViewClick}
		>
			{profilePicturesHiddenReplies.current.length > 0 && (
				<AvatarGroup
					data={profilePicturesHiddenReplies.current.map(
						(profilePicturesInfo: ProfilePictureInfo) => ({
							name: profilePicturesInfo.displayName,
							src: profilePicturesInfo.profilePicturePath,
						}),
					)}
					maxCount={maxProfilePicturesToShow}
					appearance="stack"
					size="small"
					shouldPopupRenderToParent
					testId="replies-opener-profile-pictures"
				/>
			)}
			<div css={[fg('confluence_frontend_comments_panel_v2') && textWithSpacingStyles]}>
				{!hideReplies ? (
					<Inline space="space.050" alignInline="center" alignBlock="center">
						{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- to migrate to new ADS iconography */}
						{!fg('confluence_frontend_comments_panel_v2') && (
							<ChevronDownIcon spacing="compact" label="show-more-replies-button" />
						)}
						<Text color="color.text" size="medium">
							{formatMessage(i18n.commentsPanelShowMoreRepliesText, {
								numOfMoreRepliesToDisplay: numOfMoreRepliesToDisplay.current,
							})}
						</Text>
						{canRenderBlueDotIndicator && (
							<Box
								xcss={unreadIndicatorStyle}
								testId="comments-panel-unread-comment-replies-indicator"
							>
								<Box xcss={dotStyles} />
							</Box>
						)}
					</Inline>
				) : (
					<Inline space="space.050" alignInline="center" alignBlock="center">
						{/* eslint-disable-next-line @atlaskit/design-system/no-legacy-icons -- to migrate to new ADS iconography */}
						{!fg('confluence_frontend_comments_panel_v2') && (
							<>
								<ChevronDownIcon spacing="compact" label="show-more-replies-button" />
								<ChevronUpIcon spacing="compact" label="hide-replies-button" />
							</>
						)}
						<Text color="color.text" size="medium">
							{formatMessage(i18n.commentsPanelHideRepliesText)}
						</Text>
					</Inline>
				)}
			</div>
		</div>
	);
};
