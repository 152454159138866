import React, { type ComponentType } from 'react';
import type { ExecutionResult, MutationFunctionOptions } from 'react-apollo';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import type { AnnotationInfo } from '@atlaskit/editor-plugin-annotation';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';

import { REPLY_TO_INLINE_COMMENT_EXPERIENCE } from '@confluence/experience-tracker';
import { scrollCommentIntoView, scrollToElementInEditor } from '@confluence/comments-util';
import { CommentsPanelQuery } from '@confluence/inline-comments-queries';
import {
	handleMutationFailure,
	handleCreateReplySuccess,
	updateApolloCacheCallback,
	getCommentCreationLocation,
} from '@confluence/inline-comments-common/entry-points/inlineCommentsUtils';
import { PageMode } from '@confluence/page-utils/entry-points/enums';
import type {
	ContentRepresentation,
	CommentLocation,
	InlineCommentReply,
	NewReplyVars,
} from '@confluence/inline-comments-queries';
import type { CommentData, ReplyData } from '@confluence/comments-data';
import type { ExperienceTrackerAPI } from '@confluence/experience-tracker';

import type { CommentThreadProps } from '../components/CommentThread';

export enum ViewReplyOptions {
	ALL, // show all replies, user can hide them
	HIDDEN, // show no replies, user can open them
	DEFAULT, // defaults to up to 3 unread comments, can hide/open depending on the number of unread replies
}

type HandleCommentThreadSelectionProps = {
	parentComment: CommentData;
	pageMode: PageMode;
	setCurrentlySelectedCommentMarkerRef: (parentCommentMarkerRef: string | undefined) => void;
	eventEmitter: AnnotationUpdateEmitter;
	commentThreadSelectedRef: React.MutableRefObject<boolean>;
};

export const handleCommentThreadSelection = ({
	parentComment,
	pageMode,
	setCurrentlySelectedCommentMarkerRef,
	eventEmitter,
	commentThreadSelectedRef,
}: HandleCommentThreadSelectionProps) => {
	const inlineMarkerRef = (parentComment.location as CommentLocation).inlineMarkerRef;
	const isResolved = !parentComment.isOpen;

	if (inlineMarkerRef) {
		setCurrentlySelectedCommentMarkerRef(inlineMarkerRef);

		if (!isResolved) {
			const commentElement = document.getElementById(inlineMarkerRef);
			if (commentElement) {
				if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
					scrollToElementInEditor({ targetElement: commentElement, viewingRoomOffset: -300 });
					eventEmitter.emit('setselectedannotation', inlineMarkerRef);
				} else {
					eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_FOCUS, {
						annotationId: inlineMarkerRef,
					});
					scrollCommentIntoView(commentElement, true, false, false);
				}
			}
		}
	}
	// this lets our unread logic know to mark displayed unread comments in the thread as read
	commentThreadSelectedRef.current = true;
};

type SaveProps = {
	pageId: string;
	parentCommentId: string;
	adf: object;
	onSuccess: () => void;
	pageMode: PageMode;
	editCommentQueryId: string;
	setCommentForEdit: React.Dispatch<React.SetStateAction<string>>;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	pageType: string;
	experienceTracker: ExperienceTrackerAPI;
	createReplyFn: (
		options?:
			| MutationFunctionOptions<
					{
						replyInlineComment: InlineCommentReply;
					},
					{
						input: NewReplyVars;
					}
			  >
			| undefined,
	) => Promise<
		ExecutionResult<{
			replyInlineComment: InlineCommentReply;
		}>
	>;
	selectedAnnotation: AnnotationInfo;
	addReplyToCommentThread: (ref: string, reply: ReplyData) => void;
	parentCommentMarkerRef: string;
	updateReadCommentsListState: (
		transform: (prevReadCommentsListState: Set<string>) => Set<string>,
	) => void;
};

export const onSaveComment = async ({
	pageId,
	parentCommentId,
	adf,
	onSuccess,
	pageMode,
	editCommentQueryId,
	setCommentForEdit,
	createAnalyticsEvent,
	pageType,
	experienceTracker,
	createReplyFn,
	selectedAnnotation,
	addReplyToCommentThread,
	parentCommentMarkerRef,
	updateReadCommentsListState,
}: SaveProps) => {
	const isEditor = pageMode === PageMode.EDIT;
	const variables = {
		input: {
			containerId: pageId,
			parentCommentId,
			commentBody: {
				value: JSON.stringify(adf),
				representationFormat: 'ATLAS_DOC_FORMAT' as ContentRepresentation,
			},
			createdFrom: getCommentCreationLocation(isEditor, pageMode === PageMode.LIVE),
		},
	};

	const queryVariables = {
		pageId,
	};

	return createReplyFn({
		variables,
		update: updateApolloCacheCallback(
			'create',
			queryVariables,
			CommentsPanelQuery,
			parentCommentId,
		),
	})
		.then(({ data }: any) => {
			const replyInlineComment = data.replyInlineComment;
			const reply: ReplyData = {
				isUnread: false,
				...replyInlineComment,
			};
			updateReadCommentsListState((prev) => new Set([...prev, reply.id]));
			addReplyToCommentThread(parentCommentMarkerRef, reply);
			handleCreateReplySuccess({
				onSuccess,
				editCommentQueryId,
				setCommentForEdit,
				data,
				createAnalyticsEvent,
				pageId,
				pageType,
				analyticsSource: isEditor ? 'editPageCommentsPanel' : 'viewPageCommentsPanel',
				commentId: parentCommentId,
				pageMode,
				selectedAnnotation,
				getInlineNodeTypes: undefined,
				experienceTracker,
				isEditor,
			});
		})
		.catch((error: any) => {
			return handleMutationFailure({
				experienceTracker,
				experienceName: REPLY_TO_INLINE_COMMENT_EXPERIENCE,
				error,
				commentId: parentCommentId,
				shouldReturnError: true,
			});
		});
};

export type CommentThreadMouseEnterParams = {
	id?: string;
	parentCommentMarkerRef: string;
	setIsThreadHovered: React.Dispatch<React.SetStateAction<boolean>>;
	setHoveredCommentId: React.Dispatch<React.SetStateAction<string | undefined>>;
	pageMode: PageMode;
	eventEmitter: AnnotationUpdateEmitter;
};

export const handleCommentThreadMouseEnter = ({
	id,
	parentCommentMarkerRef,
	setIsThreadHovered,
	setHoveredCommentId,
	pageMode,
	eventEmitter,
}: CommentThreadMouseEnterParams) => {
	if (parentCommentMarkerRef) {
		setIsThreadHovered(true);
		id && setHoveredCommentId(id);
		if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
			eventEmitter.emit('sethoveredannotation', parentCommentMarkerRef);
		} else {
			eventEmitter.emit(AnnotationUpdateEvent.SET_ANNOTATION_HOVERED, {
				annotationId: parentCommentMarkerRef,
			});
		}
	}
};

export const handleCommentThreadMouseLeave = ({
	parentCommentMarkerRef,
	setIsThreadHovered,
	setHoveredCommentId,
	pageMode,
	eventEmitter,
}: CommentThreadMouseEnterParams) => {
	if (parentCommentMarkerRef) {
		setIsThreadHovered(false);
		setHoveredCommentId(undefined);
		if (pageMode === PageMode.EDIT || pageMode === PageMode.LIVE) {
			eventEmitter.emit('removehoveredannotation', parentCommentMarkerRef);
		} else {
			eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_HOVERED, {
				annotationId: parentCommentMarkerRef,
			});
		}
	}
};

export const getCommentThreadsToBatch: (
	comments: CommentData[],
	passThroughProps: any,
	CommentThreadComponent: ComponentType<CommentThreadProps>,
) => React.JSX.Element[] = (comments, passThroughProps, CommentThreadComponent) =>
	comments.map((parentComment: CommentData) => (
		<CommentThreadComponent
			key={parentComment.id}
			parentComment={parentComment}
			{...passThroughProps}
		/>
	));

export const hasUnreadHiddenReply = (reply: ReplyData, hiddenReplies: ReplyData[]) => {
	return hiddenReplies.some(
		(hiddenReply: ReplyData) => hiddenReply.id === reply.id && hiddenReply.isUnread,
	);
};
