import React, { useState, useContext, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { css } from '@compiled/react';
import { FormattedMessage, defineMessages } from 'react-intl-next';

import { ChromelessEditor } from '@atlaskit/editor-core/appearance-editor-chromeless';
import { token } from '@atlaskit/tokens';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import type { AnnotationInfo } from '@atlaskit/editor-plugin-annotation';
import Button from '@atlaskit/button/new';

import { CommentEditor } from '@confluence/comment';
import { NewReplyContainer } from '@confluence/inline-comments-common/entry-points/styled';
import type {
	InlineCommentReply,
	NewReplyVars,
	CommentLocation,
} from '@confluence/inline-comments-queries';
import { CreateInlineReplyMutation } from '@confluence/inline-comments-queries';
import { useCommentsData, type CommentData, type ReplyData } from '@confluence/comments-data';
import {
	useCommentContentDispatchContext,
	useCommentContentContext,
} from '@confluence/comment-context';
import type { PageMode } from '@confluence/page-utils/entry-points/enums';
import {
	REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { useUnreadInlineComments } from '@confluence/unread-comments';
import type { PageInfoNode } from '@confluence/page-info';
import type { CommentAction } from '@confluence/inline-comments-common/entry-points/inlineCommentsTypes';
import { fg } from '@confluence/feature-gating';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { CommentWarningDialog } from '@confluence/comment-dialogs';

import {
	onSaveComment,
	ViewReplyOptions,
	hasUnreadHiddenReply,
} from '../helper/commentThreadHelper';
import { useCommentsPanel } from '../hooks/useCommentsPanel';

import { Comment } from './Comment';
import { defaultDisplayCount, RepliesToggler } from './RepliesToggler';

const commentThreadRepliesBoxStyles = css({
	display: 'flex',
	flexDirection: 'column',
	gap: token('space.200'),
});

const replyBoxStyles = css({
	listStyle: 'none',
	position: 'relative',
	paddingTop: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.300'),
});

const oldReplyBoxStyles = css({
	listStyle: 'none',
	position: 'relative',
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.200'),
});

const dynamicReplyListStyles = css({
	paddingInlineStart: token('space.0'),
	position: 'relative',
	'&::before': {
		content: "''",
		position: 'absolute',
		left: 0,
		borderLeft: `2px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		top: 0,
		bottom: `var(--replyButtonHeight)`,
		width: '2px',
	},
});

const replyListStaticStraightBorders = css({
	paddingInlineStart: token('space.0'),
	borderLeft: `2px solid ${token('color.border')}`,
});

const replyActionButtonStyles = css({
	paddingTop: token('space.100'),
	paddingBottom: token('space.100'),
	marginLeft: token('space.400', '32px'),
	width: '62px',
});

const customizedReplyButtonStyles = css({
	'&::after': {
		borderStyle: 'none',
	},
});

export type CommentRepliesProps = {
	parentComment: CommentData;
	allReplies: ReplyData[];
	unreadReplies: ReplyData[];
	pageInfo: PageInfoNode | null;
	isCurrentSelectedComment: boolean;
	hoveredCommentId: string;
	pageMode: PageMode;
	editCommentQueryId: string;
	selectedAnnotation: AnnotationInfo;
	parentCommentId: string;
	handleMouseEnter: (id?: string) => void;
	handleMouseLeave: () => void;
	commentThreadSelectedRef: React.MutableRefObject<boolean>;
};

const i18n = defineMessages({
	replyAction: {
		id: 'comments-panel.reply.action',
		defaultMessage: 'Reply',
		description: 'Reply button label for comment thread in comments panel',
	},
});

const replyBranchStyles = css({
	position: 'relative',
	// for the curvy border
	'&::before': {
		content: "''",
		position: 'absolute',
		left: '-32px',
		top: '0px',
		height: '24px',
		width: '12px',
		borderLeft: `2px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		borderBottom: `2px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		borderBottomLeftRadius: '7px',
	},
});

export const CommentReplies = ({
	parentComment,
	allReplies,
	unreadReplies,
	pageInfo,
	isCurrentSelectedComment,
	hoveredCommentId,
	pageMode,
	editCommentQueryId,
	selectedAnnotation,
	parentCommentId,
	handleMouseEnter,
	handleMouseLeave,
	commentThreadSelectedRef,
}: CommentRepliesProps) => {
	const [createReplyFn] = useMutation<
		{ replyInlineComment: InlineCommentReply },
		{ input: NewReplyVars }
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CreateInlineReplyMutation,
	);
	const { onChange, resetContentChanged } = useCommentContentDispatchContext();

	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [, { addReplyToCommentThread }] = useCommentsData();
	const [, { updateReadCommentsListState }] = useUnreadInlineComments();
	const [
		{ currentView, commentIdsByAnnotationToMarkAsRead, replyToCommentId },
		{ setCommentIdsByAnnotationToMarkAsRead, setReplyToCommentId },
	] = useCommentsPanel();

	const hiddenReplies = useRef<ReplyData[]>([]);

	const [repliesToDisplay, setRepliesToDisplay] = useState<ReplyData[]>([]);
	const [, setCommentForEdit] = useState('');
	const [currentReplyView, setReplyView] = useState(
		unreadReplies.length > 0 ? ViewReplyOptions.DEFAULT : ViewReplyOptions.HIDDEN,
	);
	const unreadRepliesAlreadyMarkedAsRead = useRef(false);

	const { hasContentChanged } = useCommentContentContext();
	const { showModal } = useDialogs();

	const experienceTracker = useContext(ExperienceTrackerContext);

	// used for reply branching styling
	const standardButtonHeight = 32;
	const replyButtonRef = useRef<HTMLDivElement | null>(null);
	// default to standard button height avoid any flickering of UI when rendering the reply branching
	const [replyButtonHeight, setReplyButtonHeight] = useState(standardButtonHeight);

	const pageId = pageInfo?.id ?? '';
	const pageType = pageInfo?.type ?? '';

	// User page permissions
	const operations = pageInfo?.operations || [];

	// The user can upload media only if they have update permissions for the page
	const hasMediaUploadPermissions = operations.some(
		(op) => op?.operation === 'update' && op?.targetType === pageType,
	);

	const isParentCommentOpen = parentComment.isOpen;
	const parentCommentMarkerRef = (parentComment?.location as CommentLocation).inlineMarkerRef || '';
	const supportedTopLevelActions: CommentAction[] = isParentCommentOpen
		? ['edit', 'resolve', 'delete']
		: [];

	// Set of displayed reply IDs for fast lookup
	// A reduce to categorize unread replies to be shown and hidden
	const { displayedUnreadReplyIds, notDisplayedUnreadReply } = unreadReplies.reduce<{
		displayedUnreadReplyIds: string[];
		notDisplayedUnreadReply: ReplyData[];
	}>(
		(acc, reply) => {
			if (repliesToDisplay.some((displayedReply) => displayedReply.id === reply.id)) {
				acc.displayedUnreadReplyIds.push(reply.id);
			} else if (
				repliesToDisplay?.length !== 0 &&
				repliesToDisplay?.length < unreadReplies.length &&
				currentReplyView !== ViewReplyOptions.ALL
			) {
				acc.notDisplayedUnreadReply.push(reply);
			}
			return acc;
		},
		{ displayedUnreadReplyIds: [], notDisplayedUnreadReply: [] },
	);

	useEffect(() => {
		if (currentReplyView === ViewReplyOptions.ALL) {
			const unreadHiddenRepliesIds = hiddenReplies.current.map((reply) => reply.id);
			setCommentIdsByAnnotationToMarkAsRead({
				parentCommentMarkerRef,
				commentIds: unreadHiddenRepliesIds,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps -- we only want to set hidden replies commentIdsByAnnotationToMarkAsRead once the reply view changes to ViewReplyOptions.ALL
	}, [currentReplyView, currentView]);

	useEffect(() => {
		if (notDisplayedUnreadReply?.length) {
			hiddenReplies.current = notDisplayedUnreadReply;
		}
	}, [notDisplayedUnreadReply]);

	useEffect(() => {
		if (replyButtonRef.current) {
			setReplyButtonHeight(replyButtonRef.current.offsetHeight || standardButtonHeight);
		}
	}, [repliesToDisplay]);

	if (commentThreadSelectedRef.current && !unreadRepliesAlreadyMarkedAsRead.current) {
		// If the comment thread is selected, we should mark all displayed unread comment ids in it as read
		// don't mark the unread replies that aren't displayed as read since the user will need to expand the reply section to see them
		const idsToMarkAsRead: string[] = [];

		idsToMarkAsRead.push(...displayedUnreadReplyIds);

		setCommentIdsByAnnotationToMarkAsRead({ parentCommentMarkerRef, commentIds: idsToMarkAsRead });
		unreadRepliesAlreadyMarkedAsRead.current = true;
	}

	const displayNewReplyContainer = isParentCommentOpen && isCurrentSelectedComment;

	const expandReplyEditor = displayNewReplyContainer && parentCommentId === replyToCommentId;

	const handleReplyButtonClick = () => {
		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					setReplyToCommentId(parentComment.id);
				},
			});
		} else {
			setReplyToCommentId(parentComment.id);
		}
	};

	const renderReplyButton = () => {
		return (
			<div
				css={[replyActionButtonStyles, replyBranchStyles]}
				ref={replyButtonRef}
				data-testid="reply-comment-action"
			>
				<Button
					data-cy="reply-comment-action"
					onClick={handleReplyButtonClick}
					css={customizedReplyButtonStyles}
					appearance="subtle"
				>
					<FormattedMessage {...i18n.replyAction} />
				</Button>
			</div>
		);
	};

	// only render reply toggler if there are replies or if there are hidden unread replies. Read replies are hidden by default
	const showRepliesToggler = allReplies.length > 0 || unreadReplies.length > defaultDisplayCount;

	return (
		<>
			<div
				data-testid={`comment-thread-${parentComment.id}-replies`}
				css={[
					commentThreadRepliesBoxStyles,
					fg('confluence_frontend_comments_panel_v2') && dynamicReplyListStyles,
				]}
				style={{ '--replyButtonHeight': `${replyButtonHeight}px` } as React.CSSProperties}
			>
				{showRepliesToggler && (
					<RepliesToggler
						hiddenRepliesRef={hiddenReplies}
						replies={allReplies}
						setRepliesToDisplay={setRepliesToDisplay}
						unreadReplies={unreadReplies}
						setReplyView={setReplyView}
						currentReplyView={currentReplyView}
						parentCommentMarkerRef={parentCommentMarkerRef}
					/>
				)}
				{repliesToDisplay.length > 0 && (
					<div
						css={[!fg('confluence_frontend_comments_panel_v2') && replyListStaticStraightBorders]}
					>
						{repliesToDisplay.map(
							(reply) =>
								reply && (
									// eslint-disable-next-line jsx-a11y/no-static-element-interactions
									<div
										css={[
											fg('confluence_frontend_comments_panel_v2')
												? replyBoxStyles
												: oldReplyBoxStyles,
										]}
										key={reply.id}
										data-testid={`comment-thread-${parentComment.id}-replies-${reply.id}`}
										onMouseEnter={() => handleMouseEnter(reply.id)}
										onMouseLeave={handleMouseLeave}
									>
										<Comment
											comment={reply}
											supportedTopLevelActions={supportedTopLevelActions}
											pageInfo={pageInfo}
											pageMode={pageMode}
											isUnread={
												(hiddenReplies.current?.length > 0 &&
													hasUnreadHiddenReply(reply, hiddenReplies.current)) ||
												(!commentIdsByAnnotationToMarkAsRead[parentCommentMarkerRef]?.has(
													reply.id,
												) &&
													reply.isUnread &&
													(currentReplyView === ViewReplyOptions.DEFAULT ||
														currentReplyView === ViewReplyOptions.ALL))
											}
											isHovered={hoveredCommentId === reply.id}
											parentCommentMarkerRef={parentCommentMarkerRef}
										/>
									</div>
								),
						)}
					</div>
				)}
				{parentComment.isOpen && fg('confluence_frontend_comments_panel_v2') && renderReplyButton()}
			</div>

			{displayNewReplyContainer && (
				<NewReplyContainer
					mode="view"
					isCommentsPanel
					data-testid="comments-panel-new-reply-container"
				>
					<CommentEditor
						pageId={pageId}
						pageType={pageType}
						appearance="chromeless"
						EditorComponent={ChromelessEditor}
						onSaveComment={(adf, onSuccess) => {
							setReplyView(ViewReplyOptions.ALL);
							setReplyToCommentId();
							return onSaveComment({
								pageId,
								parentCommentId,
								adf,
								onSuccess,
								pageMode,
								editCommentQueryId,
								setCommentForEdit,
								createAnalyticsEvent,
								pageType,
								experienceTracker,
								createReplyFn,
								selectedAnnotation,
								addReplyToCommentThread,
								parentCommentMarkerRef,
								updateReadCommentsListState,
							});
						}}
						onContentChange={onChange}
						onEditorReady={() => {
							experienceTracker.succeed({
								name: REPLY_TO_INLINE_COMMENT_LOAD_EXPERIENCE,
							});
						}}
						commentMode="reply"
						commentType="inline"
						spaceId=""
						showCancelButton
						useNewWarningModal
						hideWatchCheckbox
						expandEditor={expandReplyEditor}
						pageMode="view"
						hasMediaUploadPermissions={hasMediaUploadPermissions}
						shouldDisplayCommentReplyPrompts={false}
						topLevelCommentId={parentComment.id}
						shouldWarnOnInternalNavigation
						commentThreadLength={allReplies.length + 1}
						onCancelComment={() => {
							setReplyToCommentId();
						}}
					/>
				</NewReplyContainer>
			)}
		</>
	);
};
